<template>
  <div class="property-valuation" v-if="!['partner', 'agent'].includes($store.state.me.role)">
    <div class="main__header">
      <div class="main__header__item left"></div>
      <div class="main__header__item left">
        <h1>Оценка авто</h1>
      </div>
      <div class="main__header__item right"></div>
    </div>
    <div class="main__content lk" style="align-items: flex-start">
      <div class="property-valuation__container lk__inputs">
        <div v-if="!firm.hidden" class="property-valuation__select lk__field"
          :class="{ 'property-valuation__select--disabled': firm.disabled }">
          <label>{{ firm.title }}</label>
          <SelectComponent :options="firm.options" :placeholder="firm.title" :multiple="false" @input="onFirmChange"
            :value="firm.value ? { value: firm.value } : null" />
        </div>
        <div v-if="!model.hidden" class="property-valuation__select lk__field"
          :class="{ 'property-valuation__select--disabled': model.disabled }">
          <label>{{ model.title }}</label>
          <SelectComponent :options="model.options" :placeholder="model.title" :multiple="false" @input="onModelChange"
            :value="model.value ? { value: model.value } : null" />
        </div>
        <!-- <div v-if="!wheel.hidden" class="property-valuation__select lk__field">
          <label>{{ wheel.title }}</label>
          <SelectComponent :options="wheel.options" :placeholder="wheel.title" :multiple="false" @input="onWheelChange"
            :value="wheel.value ? { value: wheel.value } : null" />
        </div> -->
        <div v-if="!year.hidden" class="property-valuation__select lk__field"
          :class="{ 'property-valuation__select--disabled': year.disabled }">
          <label>{{ year.title }}</label>
          <SelectComponent :options="year.options" :placeholder="year.title" :multiple="false" @input="onYearChange"
            :value="year.value ? { value: year.value } : null" />
        </div>
        <div v-if="!frame.hidden" class="property-valuation__select lk__field"
          :class="{ 'property-valuation__select--disabled': frame.disabled }">
          <label>{{ frame.title }}</label>
          <SelectComponent :options="frame.options" :placeholder="frame.title" :multiple="false" @input="onFrameChange"
            :value="frame.value ? { value: frame.value } : null" />
        </div>
        <div v-if="!generation.hidden" class="property-valuation__select lk__field"
          :class="{ 'property-valuation__select--disabled': generation.disabled }">
          <label>{{ generation.title }}</label>
          <SelectComponent :options="generation.options" :placeholder="generation.title" :multiple="false"
            @input="onGenerationChange" :value="generation.value ? { value: generation.value } : null" />
        </div>
        <div v-if="!modification.hidden" class="property-valuation__select lk__field"
          :class="{ 'property-valuation__select--disabled': modification.disabled }">
          <label>{{ modification.title }}</label>
          <SelectComponent :options="modification.options" :placeholder="modification.title" :multiple="false"
            @input="onModificationChange" :value="modification.value ? { value: modification.value } : null" />
        </div>
        <div v-if="calcResult.recommendedPrice || calcResult.averageSaleDays || errors.length"
          class="property-valuation__select property-valuation__line"></div>
        <div v-if="calcResult.recommendedPrice" class="property-valuation__select lk__field"
          :class="{ 'property-valuation__select--disabled': firm.disabled }">
          <label>Средняя цена</label>
          <input type="text" disabled :value="calcResult.recommendedPrice.toLocaleString() + ' ₽'" />
        </div>
        <div v-if="calcResult.averageSaleDays" class="property-valuation__select lk__field">
          <label>Ликвидность</label>
          <input type="text" disabled :value="`${calcResult.averageSaleDays} ${calcResult.averageSaleDays && ['2', '3', '4']
            .includes(calcResult.averageSaleDays.toString().slice(-1)) ? ' дня' : 'дней'}`" />
        </div>
        <div v-if="errors && errors.length" class="messages apollo">
          <div class="messages__status error" v-for="(error, index) in errors" :key="index">{{ error }}</div>
        </div>
      </div>
      <div class="lk__back">
        <AutoIcon />
      </div>
    </div>
  </div>
</template>

<script>
import SelectInput from '@/components/ui/GraphInputs/SelectInput.vue'
import AutoIcon from '@/components/svg/Auto.vue'

import axios from 'axios'

const URL = `${process.env.VUE_APP_HTTP}/api/property-valuation`

export default {
  name: 'PropertyValuation',
  components: {
    SelectComponent: SelectInput,
    AutoIcon
  },
  data () {
    return {
      errors: [],
      calcResult: {
        recommendedPrice: null,
        averageSaleDays: null
      },
      firm: {
        title: 'Марка',
        options: [],
        value: null,
        hidden: false,
        disabled: false
      },
      model: {
        title: 'Модель',
        options: [],
        value: null,
        hidden: false,
        disabled: true
      },
      wheel: {
        title: 'Руль',
        options: [
          {
            label: 'Левый',
            value: 'LEFT'
          },
          {
            label: 'Правый',
            value: 'RIGHT'
          }
        ],
        value: 'LEFT',
        hidden: false,
        disabled: true
      },
      year: {
        title: 'Год выпуска',
        options: [],
        value: null,
        hidden: true,
        disabled: true
      },
      frame: {
        title: 'Кузов',
        options: [],
        value: null,
        hidden: true,
        disabled: true
      },
      generation: {
        title: 'Поколение',
        options: [],
        value: null,
        restylingNumber: null,
        hidden: true,
        disabled: true
      },
      modification: {
        title: 'Модификация',
        options: [],
        value: null,
        hidden: true,
        disabled: true
      }
    }
  },
  computed: {
    paramsModel () {
      return {
        firmId: this.firm.value,
        modelId: this.model.value,
        wheel: this.wheel.value,
        year: this.year.value,
        frame: this.frame.value,
        generation: this.generation.value,
        restylingNumber: this.generation.restylingNumber,
        modificationId: this.modification.value
      }
    }
  },
  created () {
    this.getFirms()
  },
  methods: {
    onModificationChange (e) {
      const selectedElem = e ? e.value : null
      this.modification.value = selectedElem

      this.errors = []
      this.calcResult = {
        recommendedPrice: null,
        averageSaleDays: null
      }

      if (!selectedElem) {
        return
      }

      this.getCalcResult()
    },
    onGenerationChange (e) {
      const selectedElem = e ? e.value : null
      this.generation.value = selectedElem
      this.generation.restylingNumber = e.restylingNumber

      this.calcResult = {
        recommendedPrice: null,
        averageSaleDays: null
      }

      this.setDefaultData('modification')

      if (!selectedElem) {
        return
      }

      this.getModifications()
      this.modification.disabled = !selectedElem
      this.modification.hidden = !selectedElem
    },
    onFrameChange (e) {
      const selectedElem = e ? e.value : null
      this.frame.value = selectedElem

      this.calcResult = {
        recommendedPrice: null,
        averageSaleDays: null
      }

      this.setDefaultData('modification')
      this.setDefaultData('generation')

      if (!selectedElem) {
        return
      }

      this.getGenerations()
      this.generation.disabled = !selectedElem
      this.generation.hidden = !selectedElem
    },
    onYearChange (e) {
      const selectedElem = e ? e.value : null
      this.year.value = selectedElem

      this.calcResult = {
        recommendedPrice: null,
        averageSaleDays: null
      }

      this.setDefaultData('modification')
      this.setDefaultData('generation')
      this.setDefaultData('frame')

      if (!selectedElem) {
        return
      }

      this.getFrames()
      this.frame.disabled = !selectedElem
      this.frame.hidden = !selectedElem
    },
    onFirmChange (e) {
      const selectedElem = e ? e.value : null
      this.firm.value = selectedElem

      this.calcResult = {
        recommendedPrice: null,
        averageSaleDays: null
      }

      this.setDefaultData('modification')
      this.setDefaultData('generation')
      this.setDefaultData('frame')
      this.setDefaultData('year')

      this.setDefaultData('model')
      this.model.hidden = false

      if (!selectedElem) {
        return
      }

      this.getModels()
      this.model.disabled = !selectedElem
      this.model.hidden = !selectedElem
    },
    onModelChange (e) {
      const selectedElem = e ? e.value : null
      this.model.value = selectedElem

      this.calcResult = {
        recommendedPrice: null,
        averageSaleDays: null
      }

      this.setDefaultData('modification')
      this.setDefaultData('generation')
      this.setDefaultData('frame')
      this.setDefaultData('year')

      if (!selectedElem) {
        return
      }

      this.getYears()
      this.year.disabled = !selectedElem
      this.year.hidden = !selectedElem
    },
    onWheelChange (e) {
      const selectedElem = e ? e.value : null
      this.wheel.value = selectedElem

      this.getYears()
      this.year.disabled = !selectedElem
      this.year.hidden = !selectedElem
    },
    async getFirms () {
      const url = `${URL}/firms`

      const params = this.paramsModel

      const response = await axios.get(url, {
        params: params
      })
        .catch(err => {
          if (err.response) {
            this.errors = [...this.errors, ...err.response.data.errors]
          }
        })

      if (!response) return

      const data = response.data
        .map(elem => ({
          label: elem.title,
          value: elem.id
        }))

      this.firm.options = this.getUniq(data, 'value')

      return response.data
    },
    async getModels () {
      const url = `${URL}/models`

      const params = this.paramsModel

      const response = await axios.get(url, {
        params: params
      })
        .catch(err => {
          if (err.response) {
            this.errors = [...this.errors, ...err.response.data.errors]
          }
        })

      if (!response) return

      const data = response.data
        .map(elem => ({
          label: elem.title,
          value: elem.id
        }))

      this.model.options = this.getUniq(data, 'value')

      return response.data
    },
    async getYears () {
      const url = `${URL}/years`

      const params = this.paramsModel

      const response = await axios.get(url, {
        params: params
      })
        .catch(err => {
          if (err.response) {
            this.errors = [...this.errors, ...err.response.data.errors]
          }
        })

      if (!response) return

      const data = response.data
        .map(elem => ({
          label: elem,
          value: elem
        }))

      this.year.options = this.getUniq(data, 'value')

      return response.data
    },
    async getFrames () {
      const url = `${URL}/frames`

      const params = this.paramsModel

      const response = await axios.get(url, {
        params: params
      })
        .catch(err => {
          if (err.response) {
            this.errors = [...this.errors, ...err.response.data.errors]
          }
        })

      if (!response) return

      const data = response.data
        .map(elem => ({
          label: elem,
          value: elem
        }))

      this.frame.options = this.getUniq(data, 'value')

      return response.data
    },
    async getGenerations () {
      const url = `${URL}/generations`

      const params = this.paramsModel

      const response = await axios.get(url, {
        params: params
      })
        .catch(err => {
          if (err.response) {
            this.errors = [...this.errors, ...err.response.data.errors]
          }
        })

      if (!response) return

      const data = response.data
        .map(elem => ({
          label: elem.title,
          value: elem.value,
          photoUrls: elem.photoUrls,
          restylingNumber: elem.restylingNumber
        }))

      this.generation.options = this.getUniq(data, 'value')

      return response.data
    },
    async getModifications () {
      const url = `${URL}/modifications`

      const params = this.paramsModel

      const response = await axios.get(url, {
        params: params
      })
        .catch(err => {
          if (err.response) {
            this.errors = [...this.errors, ...err.response.data.errors]
          }
        })

      if (!response) return

      const data = response.data
        .map(elem => ({
          label: elem.title,
          value: elem.id
        }))

      this.modification.options = this.getUniq(data, 'value')

      return response.data
    },
    async getCalcResult () {
      const url = `${URL}/calc`

      const params = this.paramsModel

      const response = await axios.get(url, {
        params: params
      })
        .catch(err => {
          if (err.response) {
            this.errors = [...this.errors, ...err.response.data.errors]
          }
        })

      if (!response) return

      const data = response.data

      this.calcResult = data

      return response.data
    },
    getUniq (array, key) {
      return array.reduce((o, i) => {
        if (!o.find(v => v[key] === i[key])) {
          o.push(i)
        }
        return o
      }, [])
    },
    setDefaultData (field) {
      this.errors = []
      this[field] = {
        title: this[field].title,
        options: [],
        value: null,
        hidden: true,
        disabled: true
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.property-valuation
  &__line
    border-bottom 1px solid $darkgray
    height 1px
    width 100%

  &__select
    margin-bottom 10px !important

    &--disabled
      pointer-events none
      opacity 0.3
</style>
